import React from 'react';
import {
  Typography, Container, Grid,
} from '@mui/material';

function Home() {
  return (
    <Container sx={{ height: '100dvh' }}>
      <Grid
        sx={{
          height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
        }}
        align="center"
        item
        md={12}
      >
        <Typography fontSize="70px">
          Riderz Labs
        </Typography>
        <Typography>
          team@riderz.io
        </Typography>
      </Grid>
    </Container>
  );
}

export default Home;
