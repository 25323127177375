import { createTheme } from '@mui/material';
import * as colors from '@mui/material/colors';

const theme = createTheme({
  typography: {
    fontFamily: 'samarkan',
    fontSize: 30,
  },
  palette: {
    primary: colors.teal,
    secondary: colors.green,
  },
});

export default theme;
